// import React, { useState } from "react";

// function YourFormComponent() {
//   const [medications, setMedications] = useState([]);
//   const [medicineName, setMedicineName] = useState("");
//   const [times, setTimes] = useState("1-1-1"); // Default value, you can set any default value you prefer
//   // ... other form states

//   // Function to handle adding a new medication
//   const addMedication = () => {
//     if (medicineName.trim() === "") {
//       // Validation for medicine name
//       alert("Please enter a valid medicine name");
//       return;
//     }

//     // Add the new medication to the list
//     setMedications([...medications, { name: medicineName, times }]);

//     // Clear input fields
//     setMedicineName("");
//     setTimes("1-1-1");
//   };

//   // ... other form handling functions

//   return (
//     <div>
//       {/* Your other form inputs */}

//       {/* Medication input fields */}
//       <div>
//         <label>Medicine Name:</label>
//         <input
//           type="text"
//           value={medicineName}
//           onChange={(e) => setMedicineName(e.target.value)}
//         />
//       </div>
//       <div>
//         <label>Times:</label>
//         <select value={times} onChange={(e) => setTimes(e.target.value)}>
//           <option value="1-1-1">1-1-1</option>
//           <option value="1-0-1">1-0-1</option>
//           {/* Add more options as needed */}
//         </select>
//       </div>
//       <button onClick={addMedication}>Add Medication</button>

//       {/* Display the list of medications */}
//       <div>
//         <h3>Medications List:</h3>
//         <ul>
//           {medications.map((medication, index) => (
//             <li key={index}>
//               {medication.name} - {medication.times}
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// }

// export default YourFormComponent;
// import React, { useState } from "react";

// function YourFormComponent() {
//   const [medications, setMedications] = useState([]);
//   const [medicineName, setMedicineName] = useState("");
//   const [times, setTimes] = useState("1-1-1");
//   const [foodTiming, setFoodTiming] = useState("before"); // Default value, you can set any default value you prefer
//   // ... other form states

//   // Function to handle adding a new medication
//   const addMedication = () => {
//     if (medicineName.trim() === "") {
//       // Validation for medicine name
//       alert("Please enter a valid medicine name");
//       return;
//     }

//     // Add the new medication to the list
//     setMedications([...medications, { name: medicineName, times, foodTiming }]);

//     // Clear input fields
//     setMedicineName("");
//     setTimes("1-1-1");
//     setFoodTiming("before");
//   };

//   // Function to handle removing a medication
//   const removeMedication = (index) => {
//     const updatedMedications = [...medications];
//     updatedMedications.splice(index, 1);
//     setMedications(updatedMedications);
//   };

//   // ... other form handling functions

//   return (
//     <div>
//       {/* Your other form inputs */}

//       {/* Medication input fields */}
//       <div>
//         <label>Medicine Name:</label>
//         <input
//           type="text"
//           value={medicineName}
//           onChange={(e) => setMedicineName(e.target.value)}
//         />
//       </div>
//       <div>
//         <label>Times:</label>
//         <select value={times} onChange={(e) => setTimes(e.target.value)}>
//           <option value="1-1-1">1-1-1</option>
//           <option value="1-0-1">1-0-1</option>
//           {/* Add more options as needed */}
//         </select>
//       </div>
//       <div>
//         <label>Timing:</label>
//         <select
//           value={foodTiming}
//           onChange={(e) => setFoodTiming(e.target.value)}
//         >
//           <option value="before">Before Food</option>
//           <option value="after">After Food</option>
//         </select>
//       </div>
//       <button onClick={addMedication}>Add Medication</button>

//       {/* Display the list of medications */}
//       <div>
//         <h3>Medications List:</h3>
//         <ul>
//           {medications.map((medication, index) => (
//             <li key={index}>
//               {medication.name} - {medication.times} -{" "}
//               {medication.foodTiming === "before"
//                 ? "Before Food"
//                 : "After Food"}
//               <button onClick={() => removeMedication(index)}>Remove</button>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// }

// export default YourFormComponent;
import React, { useEffect, useState } from "react";
import styles from "./Medication.module.css";
import HighlightOff from "@mui/icons-material/HighlightOff";

function YourFormComponent({ onMedicationDataChange }) {
  const [medications, setMedications] = useState([]);
  const [medicineName, setMedicineName] = useState("");
  const [morning, setMorning] = useState(false);
  const [noon, setNoon] = useState(false);
  const [night, setNight] = useState(false);
  const [foodTiming, setFoodTiming] = useState("BF"); // Default value, you can set any default value you prefer
  const [fieldErrors, setFieldError] = useState(null);

  // Function to handle adding a new medication
  const addMedication = async (e) => {
    e.preventDefault();
    if (medicineName.trim() === "") {
      setFieldError("Enter a medicine name");
      return;
    } else {
      setFieldError(null);
    }
    if (morning === false && noon === false && night === false) {
      setFieldError("Select atleast one timing");
      return;
    } else {
      setFieldError(null);
    }

    const newMedication = {
      [medicineName]: {
        ...(morning && { morning: foodTiming }),
        ...(noon && { noon: foodTiming }),
        ...(night && { night: foodTiming }),
      },
    };

    setMedications([...medications, newMedication]);
    // setMedications((prevMedications) => [...prevMedications, newMedication]);

    // Clear input fields
    setMedicineName("");
    setMorning(false);
    setNoon(false);
    setNight(false);
    setFoodTiming("BF");
  };

  // Function to handle removing a medication
  const removeMedication = (e, index) => {
    e.preventDefault();
    const updatedMedications = [...medications];
    updatedMedications.splice(index, 1);
    setMedications(updatedMedications);

    if (onMedicationDataChange) {
      onMedicationDataChange(updatedMedications);
    }
  };

  const handleDone = (e) => {
    // e.preventDefault();
    const formattedMedData = medications.reduce((acc, medication) => {
      const medicineName = Object.keys(medication)[0];
      acc[medicineName] = medication[medicineName];
      return acc;
    }, {});

    if (onMedicationDataChange) {
      onMedicationDataChange(formattedMedData);
    }
    console.log("Formatted Medication Data:", formattedMedData);
    // setMedications([]);
  };
  useEffect(() => {
    handleDone();
  }, [medications]);

  return (
    <div>
      <div className={styles.firstContainer}>
        <div className={styles.nameContainer}>
          <label className={styles.label}>Medicine Name {""}</label>
          <input
            type="text"
            value={medicineName}
            className={styles.medicineNameInput}
            onChange={(e) => {
              setMedicineName(e.target.value);
              setFieldError(null);
            }}
          />
        </div>
        <div className={styles.timingsContainer}>
          <label className={styles.label}>Timing : {""}</label>
          <div className={styles.checkboxContainer}>
            <label className={styles.label}>Morning</label>
            <input
              type="checkbox"
              checked={morning}
              onChange={() => {
                setMorning(!morning);
                setFieldError(null);
              }}
            />
          </div>
          <div className={styles.checkboxContainer}>
            <label className={styles.label}>Noon</label>
            <input
              type="checkbox"
              checked={noon}
              onChange={() => {
                setNoon(!noon);
                setFieldError(null);
              }}
            />
          </div>
          <div className={styles.checkboxContainer}>
            <label className={styles.label}>Night:</label>
            <input
              type="checkbox"
              checked={night}
              onChange={() => {
                setNight(!night);
                setFieldError(null);
              }}
            />
          </div>
        </div>
        <div className={styles.dropdownContainer}>
          <label className={styles.label}>Food Timing : {""}</label>
          <select
            value={foodTiming}
            className={styles.dropDownOption}
            onChange={(e) => setFoodTiming(e.target.value)}
          >
            <option value="BF" className={styles.label}>
              Before Food
            </option>
            <option value="AF" className={styles.label}>
              After Food
            </option>
          </select>
        </div>
      </div>
      <div className={styles.fieldErrorMessage}>{fieldErrors}</div>
      <button
        className={styles.addButton}
        disabled={
          medicineName.trim() === "" ||
          (morning === false && noon === false && night === false)
        }
        onClick={addMedication}
      >
        Done
      </button>

      {/* Display the list of medications */}
      {medications.length > 0 && (
        <div className={styles.listViewContainer}>
          {medications.map((medication, index) => (
            <div>
              <div className={styles.listCard} key={index}>
                {Object.keys(medication)[0]} -{" "}
                {medication[Object.keys(medication)[0]].morning && "Morning "}
                {medication[Object.keys(medication)[0]].noon && "Noon "}
                {medication[Object.keys(medication)[0]].night &&
                  "Night "} -{" "}
                {(medication[Object.keys(medication)[0]].morning ||
                  medication[Object.keys(medication)[0]].noon ||
                  medication[Object.keys(medication)[0]].night) === "BF"
                  ? "BF"
                  : "AF"}
                <HighlightOff
                  className={styles.closeButton}
                  onClick={(e) => removeMedication(e, index)}
                ></HighlightOff>
              </div>
            </div>
          ))}
          {/* <button onClick={handleDone}>Done</button> */}
        </div>
      )}
    </div>
  );
}

export default YourFormComponent;
