import React from "react";
import styles from "./PotraitSignUp.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startSession } from "../../store/actions";

function PotraitSignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const RegistrationPage = async() => {
    // Use the navigate function to navigate
    dispatch(startSession());
   await navigate("/registration");
  };

  return (
    <div className={styles.loginMain}>
      <div className={styles.loginCard}>
        <div className={styles.loginHead}>
          <div className={styles.Head1}>Welcome to</div>
          <span className={styles.Head2}>Diabbot</span>
        </div>
        <div className={styles.imgCard}>
          <div className={styles.imgCover}></div>
        </div>
        <div className={styles.click}>Click here to Register</div>
        <button className={styles.signInButton} onClick={RegistrationPage}>
          Register
        </button>
      </div>
    </div>
  );
}

export default PotraitSignUp;
