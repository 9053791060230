import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { configureStore } from '@reduxjs/toolkit';  // Import configureStore from @reduxjs/toolkit
import { Provider } from 'react-redux';
import sessionReducer from './store/reducer';

const store = configureStore({
  reducer: {
    session: sessionReducer,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode >
    <Provider store={store}>
     <App/>
    </Provider>
  </React.StrictMode>
);

