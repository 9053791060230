import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Registration from "./Components/Registration";
import PotraitSignUp from "./Components/PotraitSignUp";
import DynamicForm from "./Components/Pages/JsonQuestions";
import SuccessPage from "./Components/Pages/SuccessPage";
import ErrorPage from "./Components/Pages/ErrorPage";
import { useSelector } from "react-redux";
import JsonForm from "./Components/Registration/JsonForm";
import YourFormComponent from "./Components/rawComponents/Medication";

function App() {
  const sessionStart = useSelector((state) => state.session.sessionStart);

  const PrivateRoute = ({ path, element }) => {
    return sessionStart ? (
      <Route path={path} element={element} />
    ) : (
      <Navigate to="/error" />
    );
  };

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<PotraitSignUp />} />
          <Route path="/jsonquestions" element={<DynamicForm />} />
          {/* <Route path="/pythonPDF" element={<ErrorPage />} /> */}
          <Route path="/medication" element={<YourFormComponent />} />
          {sessionStart && (
            <Route path="/registration" element={<JsonForm />} />
            // ) : (
            //   <Route path="/registration" element={<Navigate to="*" />} />
          )}
          <Route path="/success" element={<SuccessPage />} />
          <Route path="*" element={<PotraitSignUp />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
