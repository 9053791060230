import React, { useRef, useState } from "react";
import styles from "./Registration.module.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, Divider, Snackbar } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CallIcon from "@mui/icons-material/Call";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import constants from "../../Constants/Constants";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Backdrop, CircularProgress } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useDispatch } from "react-redux";
import { endSession } from "../../store/actions";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Name is required")
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed in the name"),
  // lastName: Yup.string().required("Last Name is required"),
  height: Yup.number()
    .required("Height is required")
    .positive("Height must be a positive number")
    .max(400)
    .min(40),
  weight: Yup.number()
    .required("Weight is required")
    .positive("Weight must be a positive number")
    .max(400)
    .min(10),
  gender: Yup.string().required("Gender is required"),
  dob: Yup.date()
    .required("Date of birth is required")
    .max(new Date(), "Date of birth cannot be a future date"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{10}$/, "Phone number must be a 10-digit number"),
  latestBG: Yup.number()

    .positive("Glucose value must be a positive number")
    .max(900, "Value should be less than or equal to 900")
    .min(20, "Value should be greater than or equal to 20")
    .required("Glucose value is required"),
  // diabeticsTestInterval: Yup.string().required("Please select one"),
  // diabeticsHistory: Yup.string().required("Please select one"),
  totalMeals: Yup.number()

    .positive("Enter a positive count")
    .max(15, "Meal count should be less than or equal to 15")
    .min(1, "Meal count should be greater than or equal to 1")
    .required("Enter a value"),
  // exercise: Yup.string().required("Please select one"),
  totalSleep: Yup.number()
    .positive("Enter a positive count")
    .max(15, "Sleep hours should be less than or equal to 15")
    .min(1, "Sleep hours should be greater than or equal to 1")
    .required("Enter the value in hrs"),
  smokeOrAlcohol: Yup.string(),
  // .required("Select an option"),
  caretakerMob: Yup.string()
    // .required("Phone number is required")
    .matches(/^\d{10}$/, "Phone number must be a 10-digit number"),
});

function Registration({ formikProps }) {
  const [formData, setFormData] = useState({
    personalDetails: {
      firstName: "",
      height: "",
      weight: "",
      gender: "",
      dob: "",
      phone: "",
    },
    healthDetails: {
      latestBG: "",
      diabeticsTestInterval: "",
      diabeticsHistory: "",
      medication: "",
      totalMeals: "",
      exercise: "",
      totalSleep: "",
      smokeOrAlcohol: "",
      anySymptoms: "",
      caretakerMob: "",
    },
  });
  const modalRef = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [verificationCode, setVerificationCode] = useState("");
  const [generatedOTP, setGeneratedOTP] = useState("");
  const [isVerificationPopupOpen, setVerificationPopupOpen] = useState(false);

  const [isFetchingData, setIsFetchingData] = useState(false);

  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState("");

  const handleNextTab = () => {
    if (currentTab < 2) {
      setCurrentTab((prevTab) => prevTab + 1);
    }
  };

  const generateAndSendOTP = async () => {
    setIsFetchingData(true);
    const otp = Math.floor(100000 + Math.random() * 900000).toString();
    setGeneratedOTP(otp);

    const apiUrl = constants.WHATSAPP_TEMPLATE_URL;
    const requestBody = {
      agent_id: constants.AGENT_ID,
      to_number: phoneNumber,
      template_name: "diabbot_otp_verificatiuon",
      language_code: "en_US",
      body: {
        parameters: [
          {
            type: "text",
            text: otp,
          },
        ],
      },
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        console.log("API called successfully");
        const responseData = await response.json();
        const result = await JSON.parse(responseData);
        if (result.data.status === "ACCEPTED") {
          console.log("An OTP has been send to your number");
          setVerificationPopupOpen(true);
          setSnackbarOpen(true);
          setSnackbarColor("success");
          setSnackbarMessage("An OTP has been sent to your number");
        } else if (result.data.status === "FAILED") {
          setSnackbarOpen(true);
          setSnackbarColor("error");
          setSnackbarMessage("Please enter a valid number and try again");
          setTimeout(() => {
            setVerificationPopupOpen(false);
          }, 1500);
        }
      } else {
        console.error("Failed to send OTP ");
        setSnackbarOpen(true);
        setSnackbarColor("error");
        setSnackbarMessage("Failed to send OTP");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setSnackbarOpen(true);
      setSnackbarColor("error");
      setSnackbarMessage("Error sending OTP");
    } finally {
      setIsFetchingData(false);
    }
  };

  const verifyOTP = () => {
    if (verificationCode === generatedOTP) {
      setIsFetchingData(true);
      console.log("OTP verified successfully");
      setIsOtpVerified(true);
      // setSnackbarOpen(true);
      // setSnackbarColor("success");
      // setSnackbarMessage("OTP verified successfully");
      setTimeout(() => {
        setIsFetchingData(false);
      }, 1000);
      setTimeout(() => {
        setVerificationPopupOpen(false);
        setCurrentTab(2);
      }, 3000);
    } else {
      console.log("OTP verification failed");
      setSnackbarOpen(true);
      setSnackbarColor("error");
      setSnackbarMessage("Verification failed! Incorrect OTP");
    }
  };

  const initialMsgWhatsAppTemplateAPI = async (messageName) => {
    const apiUrl = constants.WHATSAPP_TEMPLATE_URL;
    const requestBody = {
      agent_id: constants.AGENT_ID,
      to_number: { phoneNumber },
      template_name: "diabbot_initial_message",
      language_code: "en",
      body: {
        parameters: [
          {
            type: "text",
            text: messageName,
          },
        ],
      },
    };
    console.log(JSON.stringify(requestBody));

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        setSnackbarOpen(true);
        setSnackbarColor("success");
        setSnackbarMessage("Registered Successfully");
      } else {
        setSnackbarOpen(true);
        setSnackbarColor("error");
        setSnackbarMessage("Failed to submit");
      }
    } catch (error) {
      console.error("Error");
      setSnackbarOpen(true);
      setSnackbarColor("error");
      setSnackbarMessage("Error");
    }
  };
  const handleSubmit = async (values, { setSubmitting }) => {
    if (currentTab === 1) {
      setFormData((prevData) => ({
        ...prevData,
        personalDetails: values,
      }));
      setCurrentTab(2);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        healthDetails: values,
      }));
    }

    setSubmitting(false); // Important to manually set submitting to false

    const apiUrl = "http://diabbot.oxylym.com:6500/accounts/register/";
    // Formatted data to send to BACKEND
    const formattedData = {
      latest_BG: values.latestBG,
      diabetics_test_interval:
        values.diabeticsTestInterval === ""
          ? null
          : values.diabeticsTestInterval,
      diabetics_history: values.diabeticsHistory
        ? values.diabeticsHistory.toLowerCase() === "true"
        : values.diabeticsHistory,
      medication: values.medication === "" ? null : values.medication,
      total_meals: values.totalMeals === "" ? null : values.totalMeals,
      exercise: values.exercise
        ? values.exercise.toLowerCase() === "true"
        : values.exercise,
      total_sleep: values.totalSleep === "" ? null : values.totalSleep,
      smoke_or_alcohol: values.smokeOrAlcohol
        ? values.smokeOrAlcohol.toLowerCase() === "true"
        : values.smokeOrAlcohol,
      any_symptoms: values.anySymptoms === "" ? null : values.anySymptoms,
      caretaker_mob: values.caretakerMob === "" ? null : values.caretakerMob,
      name: values.firstName,
      dob: values.dob,
      height: values.height,
      weight: values.weight,
      gender: values.gender,
      mobile: values.phone,
    };

    const messageName = values.firstName;
    console.log("Formatted Data:", formattedData);
    setIsFetchingData(true);
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set the content type to JSON
        },

        body: JSON.stringify(formattedData),
      });

      if (response.ok) {
        setSnackbarOpen(true);
        setSnackbarColor("success");
        setSnackbarMessage("Registered Successfully");
        console.log(response);
        navigate("/success");
        setTimeout(() => {
          dispatch(endSession());
        }, 1000);
        setTimeout(() => {
          initialMsgWhatsAppTemplateAPI(messageName);
        }, 1500);
        // } else if (response?.status === 400) {
        //   console.log("result");
        //   console.log(response.data.message);
        // }
      } else {
        // setSnackbarOpen(true);
        // const errorResponse = await response.json();
        // console.log("Error response:", errorResponse);
        // setSnackbarColor("error");
        // setSnackbarMessage("Failed to submit");

        const errorResponse = await response.json();
        console.log("Error response:", errorResponse);
        if (
          errorResponse.message &&
          errorResponse.message.includes("already exists")
        ) {
          setSnackbarOpen(true);
          setSnackbarColor("error");
          setSnackbarMessage(
            "Mobile number already registered. Please use a different number."
          );
        } else {
          setSnackbarOpen(true);
          setSnackbarColor("error");
          setSnackbarMessage("Failed to submit");
        }
      }
    } catch (error) {
      console.error("Error");
      setSnackbarOpen(true);
      setSnackbarColor("error");
      setSnackbarMessage("Error");
    } finally {
      setIsFetchingData(false);
    }
  };

  const handlePopUpClose = (e) => {
    setVerificationPopupOpen(false);
    setGeneratedOTP("");
    setVerificationCode("");
  };

  const handleFormField = (values) => {
    return (
      values.firstName === "" ||
      // values.lastName === "" ||
      values.gender === "" ||
      values.height === "" ||
      values.weight === ""
    );
  };

  const vertical = "center";
  const horizontal = "center";

  return (
    <div className={styles.mainContainer}>
      <div className={styles.tabContainer}>
        <div className={styles.labelHeader}>
          <label>User Registration</label>
        </div>
        <div className={styles.tabButtons}>
          <button
            // onClick={() => setCurrentTab(1)}
            className={currentTab === 1 ? styles.active : ""}
          >
            Personal Details
          </button>
          <button
            // onClick={() => setCurrentTab(2)}
            className={currentTab === 2 ? styles.active : ""}
          >
            Health Details
          </button>
        </div>

        <Formik
          initialValues={{
            firstName: "",
            // lastName: "",
            height: "",
            weight: "",
            gender: "",
            dob: "",
            phone: "",
            latestBG: "",
            diabeticsTestInterval: "",
            diabeticsHistory: false,
            medication: "",
            totalMeals: "",
            exercise: false,
            totalSleep: "",
            smokeOrAlcohol: false,
            anySymptoms: "",
            caretakerMob: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values, { setSubmitting });
          }}
        >
          {(formikProps) => (
            <Form>
              {currentTab === 1 && (
                <div>
                  <div className={styles.personalDetailsContainer}>
                    <div className={styles.formGrid}>
                      <div>
                        <label className={styles.formLabel}>Name *</label>
                        <Field
                          type="text"
                          name="firstName"
                          className={styles.fieldStyles}
                          placeholder="Enter your Full Name"
                          required
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className={styles.error}
                        />
                      </div>

                      {/* <div>
                        <label className={styles.formLabel}>Last Name</label>
                        <Field
                          type="text"
                          name="lastName"
                          className={styles.fieldStyles}
                          placeholder="Enter your Last Name"
                          required
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className={styles.error}
                        />
                      </div> */}

                      <div>
                        <label className={styles.formLabel}>
                          Date of birth *
                        </label>
                        <Field
                          type="date"
                          name="dob"
                          className={styles.fieldStyles}
                          placeholder="Select DOB"
                          max={new Date().toISOString().split("T")[0]} // Prevent selecting future dates
                          required
                        />
                        <ErrorMessage
                          name="dob"
                          component="div"
                          className={styles.error}
                        />
                      </div>

                      <div>
                        <label className={styles.formLabel}>
                          Height {"(in cm)"} *
                        </label>
                        <Field
                          type="number"
                          name="height"
                          className={styles.fieldStyles}
                          placeholder="Enter your height"
                          required
                        />
                        <ErrorMessage
                          name="height"
                          component="div"
                          className={styles.error}
                        />
                      </div>
                      <div>
                        <label className={styles.formLabel}>
                          Weight {"(in kg)"} *
                        </label>
                        <Field
                          type="number"
                          name="weight"
                          className={styles.fieldStyles}
                          placeholder="Enter your Weight"
                          required
                        />
                        <ErrorMessage
                          name="weight"
                          component="div"
                          className={styles.error}
                        />
                      </div>

                      <div>
                        <label className={styles.formLabel}>Gender *</label>
                        <div className={styles.genderRadioGroup}>
                          <div
                            role="group"
                            aria-labelledby="gender-label"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <label className={styles.radioLabel}>
                              <Field type="radio" name="gender" value="male" />
                              Male
                            </label>
                            <label className={styles.radioLabel}>
                              <Field
                                type="radio"
                                name="gender"
                                value="female"
                              />
                              Female
                            </label>
                            <label className={styles.radioLabel}>
                              <Field type="radio" name="gender" value="other" />
                              Other
                            </label>
                          </div>
                        </div>
                        <ErrorMessage
                          name="gender"
                          component="div"
                          className={styles.error}
                        />
                      </div>

                      <div>
                        <label className={styles.formLabel}>
                          WhatsApp Number *
                        </label>
                        <Field
                          type="tel"
                          name="phone"
                          className={styles.fieldStyles}
                          placeholder="Enter your Mobile Number"
                          required
                          disabled={
                            formikProps.values.firstName === "" ||
                            formikProps.values.dob === "" ||
                            formikProps.values.gender === "" ||
                            formikProps.values.height === "" ||
                            formikProps.values.weight === "" ||
                            isOtpVerified ||
                            Object.keys(formikProps.errors).length > 4
                          }
                          onChange={(e) => {
                            formikProps.handleChange(e);
                            setPhoneNumber(e.target.value);
                          }}
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className={styles.error}
                        />
                        {/* Verify button for WhatsApp number */}
                        {isOtpVerified ? (
                          <div className={styles.verifiedLabel}>
                            Verified <CheckCircleOutline />
                          </div>
                        ) : (
                          <button
                            className={styles.verifyButton}
                            type="button"
                            onClick={generateAndSendOTP}
                            disabled={
                              !(formikProps.values.phone.length === 10) ||
                              formikProps.values.firstName === "" ||
                              // formikProps.values.lastName === "" ||
                              formikProps.values.gender === "" ||
                              formikProps.values.height === "" ||
                              formikProps.values.weight === "" ||
                              isOtpVerified
                            }
                          >
                            Verify WhatsApp Number{" "}
                            <CallIcon
                              style={{
                                margin: "0px 2px 0px 2px",
                                height: "18px",
                              }}
                            />
                          </button>
                        )}
                        {isVerificationPopupOpen === true && (
                          <div className={styles.modal} ref={modalRef}>
                            <div className={styles.modalContent}>
                              {isOtpVerified === true ? (
                                <div className={styles.modalContent2}>
                                  <label
                                    className={styles.otpVerificationLabel}
                                  >
                                    Verified Successfully
                                  </label>
                                  <VerifiedIcon className={styles.tickIcon} />
                                </div>
                              ) : (
                                <>
                                  <div
                                    className={styles.closeButtonContainer}
                                    onClick={handlePopUpClose}
                                  >
                                    <HighlightOffIcon
                                      style={{ color: "grey" }}
                                    />
                                  </div>
                                  <label
                                    className={styles.otpVerificationLabel}
                                  >
                                    OTP Verification
                                  </label>
                                  <div className={styles.optImageContainer}>
                                    <img
                                      src={`${process.env.PUBLIC_URL}/otpImageDiabbot.jpg`}
                                      alt="OTPImage"
                                      className={styles.otpImage}
                                    />
                                  </div>
                                  <div className={styles.labelWording}>
                                    Enter the OTP you recieved at{" "}
                                    <label className={styles.labelNumber}>
                                      {phoneNumber}
                                    </label>
                                  </div>
                                  <input
                                    type="number"
                                    value={verificationCode}
                                    className={styles.otpField}
                                    maxLength={6}
                                    onChange={(e) =>
                                      setVerificationCode(e.target.value)
                                    }
                                  />
                                  <div className={styles.verifyBtnDiv}>
                                    <button
                                      className={styles.verifyOTPButton}
                                      onClick={verifyOTP}
                                    >
                                      Verify
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>

                      {/* NEXT BUTTON */}
                      {/* <button
                        className={styles.nextButton}
                        type="button"
                        disabled={
                          !(formikProps.values.phone.length === 10) ||
                          handleFormField(formikProps.values) ||
                          !isOtpVerified
                        }
                        onClick={handleNextTab}
                      >
                        NEXT{" "}
                        <KeyboardDoubleArrowRightIcon
                          style={{ margin: "0px 5px 0px 5px" }}
                        />
                      </button> */}
                    </div>
                  </div>
                </div>
              )}

              {currentTab === 2 && (
                <div className={styles.healthDetailsContainer}>
                  <div className={styles.questionField}>
                    <label className={styles.questionLabel}>
                      What was the result of your most recent blood glucose
                      test? {"(mg/dL)"} *
                    </label>
                    <div>
                      <Field
                        type="number"
                        name="latestBG"
                        className={styles.fieldStylesQuestions}
                        placeholder="Enter glucose value"
                      />
                      <ErrorMessage
                        name="latestBG"
                        component="div"
                        className={styles.error}
                      />
                    </div>
                  </div>
                  <Divider className={styles.dividerStyles} />
                  <div className={styles.questionField}>
                    <div>
                      <label className={styles.questionLabel}>
                        How regularly do you monitor your blood sugar levels?{" "}
                        {""}
                      </label>
                      <div
                        role="group"
                        aria-labelledby="gender-label"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "7px",
                        }}
                      >
                        <label className={styles.radioLabel}>
                          <Field
                            type="radio"
                            name="diabeticsTestInterval"
                            value="daily"
                          />
                          Daily
                        </label>
                        <label className={styles.radioLabel}>
                          <Field
                            type="radio"
                            name="diabeticsTestInterval"
                            value="weekly"
                          />
                          Weekly
                        </label>
                        <label className={styles.radioLabel}>
                          <Field
                            type="radio"
                            name="diabeticsTestInterval"
                            value="monthly"
                          />
                          Monthly
                        </label>
                      </div>
                    </div>
                    <ErrorMessage
                      name="diabeticsTestInterval"
                      component="div"
                      className={styles.error}
                    />
                  </div>
                  <Divider className={styles.dividerStyles} />
                  <div className={styles.questionField}>
                    <div>
                      <label className={styles.questionLabel}>
                        Is there a history of diabetes for your parents or
                        grandparents, indicating a hereditary connection?
                        {""}
                      </label>
                      <div
                        role="group"
                        aria-labelledby="history-label"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "7px",
                        }}
                      >
                        <label className={styles.radioLabel}>
                          <Field
                            type="radio"
                            name="diabeticsHistory"
                            value="true"
                          />
                          Yes
                        </label>
                        <label className={styles.radioLabel}>
                          <Field
                            type="radio"
                            name="diabeticsHistory"
                            value="false"
                          />
                          No
                        </label>
                      </div>
                    </div>
                    <ErrorMessage
                      name="diabeticsTestInterval"
                      component="div"
                      className={styles.error}
                    />
                  </div>
                  <Divider className={styles.dividerStyles} />
                  <div className={styles.questionField}>
                    <label className={styles.questionLabel}>
                      Are you currently taking any medication for diabetes? If
                      yes, please provide details?
                    </label>
                    <div>
                      <Field
                        type="text"
                        name="medication"
                        className={styles.fieldStylesQuestions}
                        placeholder="Enter the details"
                      />
                      <ErrorMessage
                        name="medication"
                        component="div"
                        className={styles.error}
                      />
                    </div>
                  </div>
                  <Divider className={styles.dividerStyles} />
                  <div className={styles.questionField}>
                    <label className={styles.questionLabel}>
                      How many meals do you take on a day ? *
                    </label>
                    <div>
                      <Field
                        type="number"
                        name="totalMeals"
                        className={styles.fieldStylesQuestions}
                        placeholder="No. of times"
                      />
                      <ErrorMessage
                        name="totalMeals"
                        component="div"
                        className={styles.error}
                      />
                    </div>
                  </div>
                  <Divider className={styles.dividerStyles} />
                  <div className={styles.questionField}>
                    <div>
                      <label className={styles.questionLabel}>
                        Are you engaging in regular physical activity?
                        {""}
                      </label>
                      <div
                        role="group"
                        aria-labelledby="exercise-label"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "7px",
                        }}
                      >
                        <label className={styles.radioLabel}>
                          <Field type="radio" name="exercise" value="true" />
                          Yes
                        </label>
                        <label className={styles.radioLabel}>
                          <Field type="radio" name="exercise" value="false" />
                          No
                        </label>
                      </div>
                    </div>
                    <ErrorMessage
                      name="diabeticsTestInterval"
                      component="div"
                      className={styles.error}
                    />
                  </div>
                  <Divider className={styles.dividerStyles} />
                  <div className={styles.questionField}>
                    <label className={styles.questionLabel}>
                      How many hours of sleep do you get on average each day? *
                    </label>
                    <div>
                      <Field
                        type="number"
                        name="totalSleep"
                        className={styles.fieldStylesQuestions}
                        placeholder="In Hours"
                      />
                      <ErrorMessage
                        name="totalSleep"
                        component="div"
                        className={styles.error}
                      />
                    </div>
                  </div>
                  <Divider className={styles.dividerStyles} />
                  <div className={styles.questionField}>
                    <div>
                      <label className={styles.questionLabel}>
                        Do you smoke or consume alcohol?
                        {""}
                      </label>
                      <div
                        role="group"
                        aria-labelledby="smoke-label"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "7px",
                        }}
                      >
                        <label className={styles.radioLabel}>
                          <Field
                            type="radio"
                            name="smokeOrAlcohol"
                            value="true"
                          />
                          Yes
                        </label>
                        <label className={styles.radioLabel}>
                          <Field
                            type="radio"
                            name="smokeOrAlcohol"
                            value="false"
                          />
                          No
                        </label>
                      </div>
                    </div>
                    <ErrorMessage
                      name="smokeOrAlcohol"
                      component="div"
                      className={styles.error}
                    />
                  </div>
                  <Divider className={styles.dividerStyles} />
                  <div className={styles.questionField}>
                    <label className={styles.questionLabel}>
                      Are you currently experiencing any other health situation?
                    </label>
                    <div>
                      <Field
                        type="text"
                        name="anySymptoms"
                        className={styles.fieldStylesQuestions}
                        placeholder="Please fill in if any"
                      />
                      <ErrorMessage
                        name="anySymptoms"
                        component="div"
                        className={styles.error}
                      />
                    </div>
                  </div>
                  <Divider className={styles.dividerStyles} />

                  <div className={styles.questionField}>
                    <label className={styles.questionLabel}>
                      Please provide the mobile number of your caretaker (if
                      any) ?
                    </label>
                    <div>
                      <Field
                        type="tel"
                        name="caretakerMob"
                        className={styles.fieldStylesQuestions}
                        placeholder="Phone Number"
                      />
                      <ErrorMessage
                        name="caretakerMob"
                        component="div"
                        className={styles.error}
                      />
                    </div>
                  </div>
                  <button
                    className={styles.nextButton}
                    type="submit"
                    disabled={
                      formikProps.values.latestBG === "" ||
                      formikProps.values.totalMeals === "" ||
                      formikProps.values.totalSleep === "" ||
                      Object.keys(formikProps.errors).length > 0
                    }
                  >
                    Submit to Register
                  </button>
                </div>
              )}
            </Form>
          )}
        </Formik>
        <Backdrop open={isFetchingData} style={{ zIndex: 1, color: "#fff" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          key={vertical + horizontal}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarColor}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default Registration;
