import React, { useState } from "react";
import questionsData from "./questions.json";
import styles from "../../../Components/Registration/JsonForm/JsonForm.module.css";
import { Divider } from "@mui/material";
import Select from "react-select";
import Medication from "../../rawComponents/Medication";

const DynamicForm = () => {
  const formDataTemplate = {};
  const [formData, setFormData] = useState(formDataTemplate);
  const [errors, setErrors] = useState({});
  const [medicationData, setMedicationData] = useState({});

  const handleInputChange = (id, value) => {
    const question = questionsData.questions.find((q) => q.id === id);
    const { fieldType, min, max, pattern, inputType, allowed } = question;

    // Validate against the specified range for number input type
    if (fieldType === "textField" && inputType === "number") {
      if (value !== "" && (value < min || value > max)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: `Value must be between ${min} and ${max}`,
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
      }
    }

    // Validate against alphanumeric pattern for text input type
    if (
      fieldType === "textField" &&
      inputType === "text" &&
      allowed === "alphanumeric"
    ) {
      const alphanumericPattern = /^[a-zA-Z0-9 ]*$/;

      if (value !== "" && !alphanumericPattern.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: "Only alphanumeric characters are allowed.",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
      }
    }

    // Validate against numeric pattern and length for phone number input type
    if (
      fieldType === "textField" &&
      inputType === "tel" &&
      allowed === "numeric"
    ) {
      const phoneNumberPattern = /^\d+$/;

      if (
        value !== "" &&
        (!phoneNumberPattern.test(value) || value.length !== 10)
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: "Please enter a valid 10-digit phone number.",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
      }
    }

    // Update the form data
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  // const handleMedicationDataChange = (medicationData) => {
  //   // Do something with the medication data
  //   console.log("Medication Data in AnotherFile:", medicationData);
  // };

  const [formattedMedFormData, setFormattedMedFormData] = useState({});

  const handleMedicationDataChange = (formattedMedData) => {
    const medicationQuestion = questionsData.questions.find(
      (question) => question.fieldType === "medicationField"
    );

    setFormattedMedFormData({
      ...formattedMedFormData,
      [medicationQuestion.question]: formattedMedData,
    });

    // Update medication data in DynamicForm
    setMedicationData({
      // ...medicationData,
      ...formattedMedData,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check for errors before submitting
    const newErrors = {};
    const formattedFormData = {};

    questionsData.questions.forEach((question) => {
      const { id, required, min, max, question: questionText } = question;
      const value = formData[id];
      // console.log(typeof value);
      // Check if the question is required and the value is not provided
      if (
        required &&
        (value === "" ||
          (typeof value === "number" && (value < min || value > max)))
      ) {
        newErrors[id] = "This field is required.";
      } else if (value !== "" && (value < min || value > max)) {
        newErrors[id] = `Value must be between ${min} and ${max}`;
      }

      formattedFormData[questionText] =
        typeof value === "number"
          ? value
          : value != null
          ? value.toString()
          : null;
    });

    const medicationQuestion = questionsData.questions.find(
      (question) => question.fieldType === "medicationField"
    );

    // formattedFormData[medicationQuestion.question] = medicationData;
    formattedFormData.medication = medicationData;

    // Update the state with errors
    setErrors(newErrors);

    // If there are errors, prevent form submission
    if (Object.keys(newErrors).length > 0) {
      return;
    }

    console.log("Formatted Form Data:", formattedFormData);
  };

  const isSubmitDisabled =
    Object.values(errors).some((error) => error !== "") ||
    questionsData.questions.some((question) => {
      const { id, required, fieldType } = question;
      const value = formData[id];

      if (
        required &&
        (!value || (Array.isArray(value) && value.length === 0))
      ) {
        return true;
      }
      return false;
    });

  return (
    <div>
      <div
        className={styles.healthDetailsContainer}
        style={{ backgroundColor: "rgb(228, 228, 228)" }}
      >
        <div className={styles.questionField}>
          <form onSubmit={handleSubmit}>
            {questionsData.questions.map((question) => {
              const {
                id,
                question: questionText,
                fieldType,
                inputType,
                options,
                min,
                max,
                pattern,
                required,
              } = question;

              return (
                <div key={id}>
                  <label className={styles.questionLabel}>
                    {questionText}
                    {required === true && <label>{" *"}</label>}
                  </label>
                  {fieldType === "textField" && (
                    <div>
                      {inputType === "text" && (
                        <input
                          type="text"
                          value={formData[id] || ""}
                          className={styles.fieldStylesQuestions}
                          onChange={(e) =>
                            handleInputChange(id, e.target.value)
                          }
                        />
                      )}
                      {inputType === "number" && (
                        <input
                          type="number"
                          value={formData[id] || ""}
                          min={min || undefined}
                          max={max || undefined}
                          className={styles.fieldStylesQuestions}
                          onChange={(e) =>
                            handleInputChange(id, e.target.value)
                          }
                        />
                      )}
                      {inputType === "tel" && (
                        <input
                          type="tel"
                          pattern={pattern}
                          value={formData[id] || ""}
                          className={styles.fieldStylesQuestions}
                          onChange={(e) =>
                            handleInputChange(id, e.target.value)
                          }
                        />
                      )}
                      {inputType === "email" && (
                        <input
                          type="email"
                          value={formData[id] || ""}
                          className={styles.fieldStylesQuestions}
                          onChange={(e) =>
                            handleInputChange(id, e.target.value)
                          }
                        />
                      )}
                      {inputType === "date" && (
                        <input
                          type="date"
                          value={formData[id] || ""}
                          className={styles.fieldStylesQuestions}
                          onChange={(e) =>
                            handleInputChange(id, e.target.value)
                          }
                        />
                      )}
                      {inputType === "time" && (
                        <input
                          type="time"
                          value={formData[id] || ""}
                          className={styles.fieldStylesQuestions}
                          onChange={(e) =>
                            handleInputChange(id, e.target.value)
                          }
                        />
                      )}
                      {inputType === "password" && (
                        <input
                          type="password"
                          value={formData[id] || ""}
                          className={styles.fieldStylesQuestions}
                          onChange={(e) =>
                            handleInputChange(id, e.target.value)
                          }
                        />
                      )}
                    </div>
                  )}
                  {fieldType === "radioButton" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {options.map((option) => (
                        <label
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "7px",
                          }}
                          className={styles.radioLabel}
                          key={option}
                        >
                          <input
                            type="radio"
                            name={`radio_${id}`}
                            value={option}
                            className={styles.radioLabel}
                            checked={formData[id] === option}
                            onChange={() => handleInputChange(id, option)}
                          />
                          {option}
                        </label>
                      ))}
                    </div>
                  )}
                  {fieldType === "dropdown" && (
                    <>
                      <Select
                        value={options.find(
                          (option) => option.value === formData[id]
                        )}
                        options={[
                          { label: "Select...", value: null },
                          ...options.map((option) => ({
                            label: option,
                            value: option,
                          })),
                        ]}
                        onChange={(selectedOption) =>
                          handleInputChange(
                            id,
                            selectedOption ? selectedOption.value : null
                          )
                        }
                        className={styles.fieldStylesMultiDropDown}
                      />
                    </>
                  )}
                  {fieldType === "medicationField" && (
                    <Medication
                      onMedicationDataChange={handleMedicationDataChange}
                    />
                  )}
                  {fieldType === "multiSelect" && (
                    <Select
                      isMulti
                      options={options.map((option) => ({
                        value: option,
                        label: option,
                      }))}
                      value={(formData[id] || []).map((option) => ({
                        value: option,
                        label: option,
                      }))}
                      className={styles.fieldStylesMultiDropDown}
                      onChange={(selectedOptions) =>
                        handleInputChange(
                          id,
                          selectedOptions.map((option) => option.value)
                        )
                      }
                    />
                  )}
                  {/* ... Other field types ... */}
                  {/* {errors[id] && (
                    <div style={{ color: "red" }}>{errors[id]}</div>
                  )} */}
                  {errors[id] && <p className={styles.error}>{errors[id]}</p>}
                  <Divider className={styles.dividerStyles} />
                </div>
              );
            })}
            <label className={styles.mandatoryMessage}>
              Fill all mandatory * questions to submit.
            </label>
            <div className={styles.submitContainer}>
              <button
                type="submit"
                className={styles.nextButton}
                disabled={isSubmitDisabled}
              >
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DynamicForm;
