import React, { useRef, useState } from "react";
import styles from "./JsonForm.module.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, Divider, Snackbar } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CallIcon from "@mui/icons-material/Call";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import constants from "../../../Constants/Constants";
import { Backdrop, CircularProgress } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useDispatch } from "react-redux";
import { endSession } from "../../../store/actions";
import { useNavigate } from "react-router-dom";
import questionsData from "../../Pages/JsonQuestions/questions.json";
import moment from "moment";
import "moment-timezone";
import Select from "react-select";
// import {
//   CountrySelector,
//   CountrySelectorDropdown,
//   DialCodePreview,
//   FlagImage,
//   PhoneInput,
// } from "react-international-phone";
// import "react-international-phone/style.css";
import PhoneInput from "react-phone-input-2";
// import PhoneInput from "react-phone-number-input";
import "react-phone-input-2/lib/style.css";
// import "react-phone-number-input/style.css";
import Medication from "../../rawComponents/Medication";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Name is required")
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed in the name"),
  // lastName: Yup.string().required("Last Name is required"),
  height: Yup.number()
    .required("Height is required")
    .positive("Height must be a positive number")
    .max(400)
    .min(40),
  weight: Yup.number()
    .required("Weight is required")
    .positive("Weight must be a positive number")
    .max(400)
    .min(10),
  gender: Yup.string().required("Gender is required"),
  dob: Yup.date()
    .required("Date of birth is required")
    .max(new Date(), "Date of birth cannot be a future date"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{10}$/, "Phone number must be a 10-digit number"),
  timeZone: Yup.string().required("Time Zone is required"),
});

function JsonForm({ formikProps }) {
  const modalRef = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [formDataFirstTab, setFormDataFirstTab] = useState(null);
  const [formDataSecondTab, setFormDataSecondTab] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [verificationCode, setVerificationCode] = useState("");
  const [generatedOTP, setGeneratedOTP] = useState("");
  const [isVerificationPopupOpen, setVerificationPopupOpen] = useState(false);

  const [isFetchingData, setIsFetchingData] = useState(false);

  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState("");

  const timeZones = moment.tz.names();

  const [medicationData, setMedicationData] = useState({});

  const handleSubmitFirstTab = (values) => {
    setFormDataFirstTab(values);
    setCurrentTab(2);
    console.log(formDataFirstTab);
  };

  const handleNextTab = () => {
    if (currentTab < 2) {
      setCurrentTab((prevTab) => prevTab + 1);
    }
  };

  const generateAndSendOTP = async (formData1) => {
    console.log(formData1);
    setIsFetchingData(true);
    const otp = Math.floor(100000 + Math.random() * 900000).toString();
    setGeneratedOTP(otp);

    // const apiUrl = constants.WHATSAPP_TEMPLATE_URL;
    // const requestBody = {
    //   agent_id: constants.AGENT_ID,
    //   to_number: phoneNumber,
    //   template_name: "diabbot_otp_verificatiuon",
    //   language_code: "en_US",
    //   body: {
    //     parameters: [
    //       {
    //         type: "text",
    //         text: otp,
    //       },
    //     ],
    //   },
    // };

    const apiUrl = `${constants.BACKEND_URL}/accounts/send_otp/`;

    const requestBody = {
      otp: otp,
      mob: phoneNumber,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response?.status === 201) {
        await setFormDataFirstTab(formData1);
        await console.log(formDataFirstTab);
        console.log("API called successfully");
        // const responseData = await response.json();
        // const result = await JSON.parse(responseData);
        // if (result.data.status === "ACCEPTED") {
        console.log("An OTP has been send to your number");
        setVerificationPopupOpen(true);
        setSnackbarOpen(true);
        setSnackbarColor("success");
        setSnackbarMessage("An OTP has been sent to your number");
        // } else if (result.data.status === "FAILED") {
        //   setVerificationPopupOpen(true);
        //   setSnackbarOpen(true);
        //   setSnackbarColor("error");
        //   setSnackbarMessage("Please enter a valid number and try again");
        //   setTimeout(() => {
        //     setVerificationPopupOpen(false);
        //   }, 1500);
        // }
      } else if (response?.status === 400) {
        const errorResponse = await response.json();
        console.log("Failed to send OTP ", errorResponse?.message);
        setSnackbarOpen(true);
        setSnackbarColor("error");
        setSnackbarMessage(errorResponse?.message);
      } else {
        console.log("Error sending OTP");
        setSnackbarOpen(true);
        setSnackbarColor("error");
        setSnackbarMessage("Error sending OTP");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setSnackbarOpen(true);
      setSnackbarColor("error");
      setSnackbarMessage("Error sending OTP");
    } finally {
      setIsFetchingData(false);
    }
  };

  const verifyOTP = () => {
    if (verificationCode === generatedOTP) {
      setIsFetchingData(true);
      console.log("OTP verified successfully");
      setIsOtpVerified(true);
      // setSnackbarOpen(true);
      // setSnackbarColor("success");
      // setSnackbarMessage("OTP verified successfully");
      setTimeout(() => {
        setIsFetchingData(false);
      }, 1000);
      setTimeout(() => {
        setVerificationPopupOpen(false);
        setCurrentTab(2);
      }, 3000);
    } else {
      console.log("OTP verification failed");
      setSnackbarOpen(true);
      setSnackbarColor("error");
      setSnackbarMessage("Verification failed! Incorrect OTP");
    }
  };

  const initialMsgWhatsAppTemplateAPI = async (messageName) => {
    const apiUrl = constants.WHATSAPP_TEMPLATE_URL;
    const requestBody = {
      agent_id: constants.AGENT_ID,
      to_number: { phoneNumber },
      template_name: "diabbot_initial_message",
      language_code: "en",
      body: {
        parameters: [
          {
            type: "text",
            text: messageName,
          },
        ],
      },
    };
    console.log(JSON.stringify(requestBody));

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        setSnackbarOpen(true);
        setSnackbarColor("success");
        setSnackbarMessage("Registered Successfully");
      } else {
        setSnackbarOpen(true);
        setSnackbarColor("error");
        setSnackbarMessage("Failed to submit");
      }
    } catch (error) {
      console.error("Error");
      setSnackbarOpen(true);
      setSnackbarColor("error");
      setSnackbarMessage("Error");
    }
  };

  const handlePopUpClose = (e) => {
    setVerificationPopupOpen(false);
    setGeneratedOTP("");
    setVerificationCode("");
  };

  const handleFormField = (values) => {
    return (
      values.firstName === "" ||
      // values.lastName === "" ||
      values.gender === "" ||
      values.height === "" ||
      values.weight === ""
    );
  };

  const vertical = "center";
  const horizontal = "center";

  const formDataTemplate = {};
  const [formData, setFormData] = useState(formDataTemplate);
  const [errors, setErrors] = useState({});

  //   const handleInputChange = (id, value) => {
  //     const question = questionsData.questions.find((q) => q.id === id);
  //     const { min, max } = question;

  //     // Validate against the specified range
  //     if (value !== "" && (value < min || value > max)) {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [id]: `Value must be between ${min} and ${max}`,
  //       }));
  //     } else {
  //       setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
  //     }

  //     setFormData((prevData) => ({ ...prevData, [id]: value }));
  //   };
  const handleInputChange = (id, value) => {
    const question = questionsData.questions.find((q) => q.id === id);
    const { fieldType, min, max, pattern, inputType, allowed } = question;

    // Validate against the specified range for number input type

    if (fieldType === "textField" && inputType === "number") {
      if (value !== "" && (value < min || value > max)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: `Value must be between ${min} and ${max}`,
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
      }
    }

    // Validate against alphanumeric pattern for text input type
    if (
      fieldType === "textField" &&
      inputType === "text" &&
      allowed === "alphanumeric"
    ) {
      const alphanumericPattern = /^[a-zA-Z0-9 ]*$/;

      if (value !== "" && !alphanumericPattern.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: "Only alphanumeric characters are allowed.",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
      }
    }

    // Validate against numeric pattern and length for phone number input type
    if (
      fieldType === "textField" &&
      inputType === "tel" &&
      allowed === "numeric"
    ) {
      const phoneNumberPattern = /^\d+$/;

      if (
        value !== "" &&
        (!phoneNumberPattern.test(value) || value.length !== 10)
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: "Please enter a valid 10-digit phone number.",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
      }
    }

    // Update the form data
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const [formattedMedFormData, setFormattedMedFormData] = useState({});

  const handleMedicationDataChange = (formattedMedData) => {
    const medicationQuestion = questionsData.questions.find(
      (question) => question.fieldType === "medicationField"
    );

    setFormattedMedFormData({
      ...formattedMedFormData,
      [medicationQuestion.question]: formattedMedData,
    });

    // Update medication data in DynamicForm
    setMedicationData({
      // ...medicationData,
      ...formattedMedData,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for errors before submitting
    const newErrors = {};
    const formattedFormData = {};

    questionsData.questions.forEach((question) => {
      const { id, required, min, max, question: questionText } = question;
      const value = formData[id];

      // Check if the question is required and the value is not provided
      if (
        required &&
        (value === "" ||
          (typeof value === "number" && (value < min || value > max)))
      ) {
        newErrors[id] = "This field is required.";
      } else if (value !== "" && (value < min || value > max)) {
        newErrors[id] = `Value must be between ${min} and ${max}`;
      }

      formattedFormData[questionText] =
        typeof value === "number"
          ? value
          : value != null
          ? value.toString()
          : null;
    });

    const medicationQuestion = questionsData.questions.find(
      (question) => question.fieldType === "medicationField"
    );

    // formattedFormData[medicationQuestion.question] = medicationData;
    formattedFormData.medication = medicationData;

    // Update the state with errors
    setErrors(newErrors);

    // If there are errors, prevent form submission
    if (Object.keys(newErrors).length > 0) {
      return;
    }
    // console.log("Formatted Form Data:", formattedFormData);

    const apiUrl = `${constants.BACKEND_URL}/accounts/register/`;
    // Formatted data to send to BACKEND
    const formattedData = {
      name: formDataFirstTab.firstName,
      dob: formDataFirstTab.dob,
      height: formDataFirstTab.height,
      weight: formDataFirstTab.weight,
      gender: formDataFirstTab.gender,
      mobile: formDataFirstTab.phone,
      health_details: formattedFormData,
      medication: medicationData,
    };

    const messageName = formDataFirstTab.firstName;
    console.log("Formatted Data:", formattedData);
    setIsFetchingData(true);
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set the content type to JSON
        },

        body: JSON.stringify(formattedData),
      });

      if (response.ok) {
        setSnackbarOpen(true);
        setSnackbarColor("success");
        setSnackbarMessage("Registered Successfully");
        console.log(response);
        navigate("/success", phoneNumber);
        setTimeout(() => {
          dispatch(endSession());
        }, 1000);
        // setTimeout(() => {
        //   initialMsgWhatsAppTemplateAPI(messageName);
        // }, 1500);
        // } else if (response?.status === 400) {
        //   console.log("result");
        //   console.log(response.data.message);
        // }
      } else {
        // setSnackbarOpen(true);
        // const errorResponse = await response.json();
        // console.log("Error response:", errorResponse);
        // setSnackbarColor("error");
        // setSnackbarMessage("Failed to submit");

        const errorResponse = await response.json();
        console.log("Error response:", errorResponse);
        if (
          errorResponse.message &&
          errorResponse.message.includes("already exists")
        ) {
          setSnackbarOpen(true);
          setSnackbarColor("error");
          setSnackbarMessage(
            "Mobile number already registered. Please use a different number."
          );
        } else {
          setSnackbarOpen(true);
          setSnackbarColor("error");
          setSnackbarMessage("Failed to submit");
        }
      }
    } catch (error) {
      console.error("Error");
      setSnackbarOpen(true);
      setSnackbarColor("error");
      setSnackbarMessage("Error");
    } finally {
      setIsFetchingData(false);
    }
  };

  // Check if there are any errors or required fields not filled
  const isSubmitDisabled =
    Object.values(errors).some((error) => error !== "") ||
    questionsData.questions.some(
      (question) => question.required && !formData[question.id]
    );

  const handleOTPinput = async (e) => {
    // Allow only numeric input
    const numericInput = e.target.value.replace(/\D/g, "");

    // Update the state
    await setVerificationCode(numericInput);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.tabContainer}>
        <div className={styles.labelHeader}>
          <label>User Registration</label>
          <div className={styles.circularImageContainer}>
            <img
              src={`${process.env.PUBLIC_URL}/LogoBlue.svg`}
              alt="User Profile"
              className={styles.circularImage}
            />
          </div>
        </div>
        <div className={styles.tabButtons}>
          <button
            // onClick={() => setCurrentTab(1)}
            className={currentTab === 1 ? styles.active : ""}
          >
            Personal Details
          </button>
          <button
            // onClick={() => setCurrentTab(2)}
            className={currentTab === 2 ? styles.active : ""}
          >
            Health Details
          </button>
        </div>

        {currentTab === 1 && (
          <Formik
            initialValues={{
              firstName: "",
              height: "",
              weight: "",
              gender: "",
              dob: "",
              phone: "",
              timeZone: "",
            }}
            validationSchema={validationSchema}
            onSubmit={""}
          >
            {(formikProps) => (
              <Form>
                <div>
                  <div className={styles.personalDetailsContainer}>
                    <div className={styles.formGrid}>
                      <div>
                        <label className={styles.formLabel}>Name *</label>
                        <Field
                          type="text"
                          name="firstName"
                          className={styles.fieldStyles}
                          placeholder="Enter your Full Name"
                          required
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className={styles.error}
                        />
                      </div>

                      <div>
                        <label className={styles.formLabel}>
                          Date of birth *
                        </label>
                        <Field
                          type="date"
                          name="dob"
                          className={styles.fieldStyles}
                          placeholder="Select DOB"
                          max={new Date().toISOString().split("T")[0]} // Prevent selecting future dates
                          required
                        />
                        <ErrorMessage
                          name="dob"
                          component="div"
                          className={styles.error}
                        />
                      </div>

                      <div>
                        <label className={styles.formLabel}>
                          Height {"(in cm)"} *
                        </label>
                        <Field
                          type="number"
                          name="height"
                          className={styles.fieldStyles}
                          placeholder="Enter your height"
                          required
                        />
                        <ErrorMessage
                          name="height"
                          component="div"
                          className={styles.error}
                        />
                      </div>
                      <div>
                        <label className={styles.formLabel}>
                          Weight {"(in kg)"} *
                        </label>
                        <Field
                          type="number"
                          name="weight"
                          className={styles.fieldStyles}
                          placeholder="Enter your Weight"
                          required
                        />
                        <ErrorMessage
                          name="weight"
                          component="div"
                          className={styles.error}
                        />
                      </div>

                      <div>
                        <label className={styles.formLabel}>Gender *</label>
                        <div className={styles.genderRadioGroup}>
                          <div
                            role="group"
                            aria-labelledby="gender-label"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <label className={styles.radioLabel}>
                              <Field type="radio" name="gender" value="male" />
                              Male
                            </label>
                            <label className={styles.radioLabel}>
                              <Field
                                type="radio"
                                name="gender"
                                value="female"
                              />
                              Female
                            </label>
                            <label className={styles.radioLabel}>
                              <Field type="radio" name="gender" value="other" />
                              Other
                            </label>
                          </div>
                        </div>
                        <ErrorMessage
                          name="gender"
                          component="div"
                          className={styles.error}
                        />
                      </div>

                      <div>
                        <label className={styles.formLabel}>Time Zone *</label>
                        <Field
                          as="select"
                          name="timeZone"
                          className={styles.fieldStylesTimezone}
                          required
                        >
                          <option value="" label="Select Time Zone" />
                          {timeZones.map((zone) => (
                            <option key={zone} value={zone} label={zone} />
                          ))}
                        </Field>
                        <ErrorMessage
                          name="timeZone"
                          component="div"
                          className={styles.error}
                        />
                      </div>

                      <div>
                        <label className={styles.formLabel}>
                          WhatsApp Number *
                        </label>
                        <PhoneInput
                          countryCallingCodeEditable={false}
                          international
                          country="in"
                          disabled={
                            formikProps.values.firstName === "" ||
                            formikProps.values.dob === "" ||
                            formikProps.values.gender === "" ||
                            formikProps.values.height === "" ||
                            formikProps.values.weight === "" ||
                            formikProps.values.timeZone === "" ||
                            isOtpVerified ||
                            formikProps.errors.dob ||
                            formikProps.errors.firstName ||
                            formikProps.errors.gender ||
                            formikProps.errors.height ||
                            formikProps.errors.weight ||
                            formikProps.errors.timeZone
                          }
                          // countryCodeEditable={false}
                          defaultCountry="IN" // Set the default country
                          value={formikProps.values.phone}
                          inputStyle={{
                            width: "100%",
                            border: "none",
                            fontFamily: "sans-serif !important",
                            fontSize: "small",
                          }}
                          className={styles.fieldStylesPhone}
                          onChange={(value) => {
                            setPhoneNumber(value);
                            formikProps.handleChange({
                              target: {
                                name: "phone",
                                value: value,
                              },
                            });
                          }}
                          placeholder="Enter your WhatsApp Number"
                          inputProps={{
                            name: "phone",
                            required: true,
                          }}
                          // Other customization options can be passed here
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className={styles.error}
                        />
                        {/* Verify button for WhatsApp number */}
                        {isOtpVerified ? (
                          <div className={styles.verifiedLabel}>
                            Verified <CheckCircleOutline />
                          </div>
                        ) : (
                          <button
                            className={styles.verifyButton}
                            type="button"
                            onClick={() =>
                              generateAndSendOTP(formikProps.values)
                            }
                            disabled={
                              // !(formikProps.values.phone.length === 10) ||
                              formikProps.values.firstName === "" ||
                              formikProps.values.dob === "" ||
                              formikProps.values.timeZone === "" ||
                              formikProps.values.gender === "" ||
                              formikProps.values.height === "" ||
                              formikProps.values.weight === "" ||
                              isOtpVerified ||
                              Object.keys(formikProps.errors).length > 1
                            }
                          >
                            Verify WhatsApp Number{" "}
                            <CallIcon
                              style={{
                                margin: "0px 2px 0px 2px",
                                height: "18px",
                              }}
                            />
                          </button>
                        )}
                        {isVerificationPopupOpen === true && (
                          <div className={styles.modal} ref={modalRef}>
                            <div className={styles.modalContent}>
                              {isOtpVerified === true ? (
                                <div className={styles.modalContent2}>
                                  <label
                                    className={styles.otpVerificationLabel}
                                  >
                                    Verified Successfully
                                  </label>
                                  <VerifiedIcon className={styles.tickIcon} />
                                </div>
                              ) : (
                                <>
                                  <div
                                    className={styles.closeButtonContainer}
                                    onClick={handlePopUpClose}
                                  >
                                    <HighlightOffIcon
                                      style={{ color: "grey" }}
                                    />
                                  </div>
                                  <label
                                    className={styles.otpVerificationLabel}
                                  >
                                    OTP Verification
                                  </label>
                                  <div className={styles.optImageContainer}>
                                    <img
                                      src={`${process.env.PUBLIC_URL}/otpImageDiabbot.jpg`}
                                      alt="OTPImage"
                                      className={styles.otpImage}
                                    />
                                  </div>
                                  <div className={styles.labelWording}>
                                    Enter the OTP you recieved at{" "}
                                    <label className={styles.labelNumber}>
                                      {"+" + phoneNumber}
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    value={verificationCode}
                                    className={styles.otpField}
                                    maxLength={6}
                                    inputMode="numeric"
                                    onChange={handleOTPinput}
                                  />
                                  <div className={styles.verifyBtnDiv}>
                                    <button
                                      className={styles.verifyOTPButton}
                                      onClick={verifyOTP}
                                    >
                                      Verify
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
        {currentTab === 2 && (
          <div>
            <div className={styles.healthDetailsContainer}>
              <div className={styles.questionField}>
                <form onSubmit={handleSubmit}>
                  {questionsData.questions.map((question) => {
                    const {
                      id,
                      question: questionText,
                      fieldType,
                      inputType,
                      options,
                      min,
                      max,
                      pattern,
                      required,
                    } = question;
                    return (
                      <div key={id}>
                        <label className={styles.questionLabel}>
                          {questionText}
                          {required === true && <label>{" *"}</label>}
                        </label>
                        {fieldType === "textField" && (
                          <div>
                            {inputType === "text" && (
                              <input
                                type="text"
                                value={formData[id] || ""}
                                className={styles.fieldStylesQuestions}
                                onChange={(e) =>
                                  handleInputChange(id, e.target.value)
                                }
                              />
                            )}
                            {inputType === "number" && (
                              <input
                                type="number"
                                value={formData[id] || ""}
                                min={min || undefined}
                                max={max || undefined}
                                className={styles.fieldStylesQuestions}
                                onChange={(e) =>
                                  handleInputChange(id, e.target.value)
                                }
                              />
                            )}
                            {inputType === "tel" && (
                              <input
                                type="tel"
                                pattern={pattern}
                                value={formData[id] || ""}
                                className={styles.fieldStylesQuestions}
                                onChange={(e) =>
                                  handleInputChange(id, e.target.value)
                                }
                              />
                            )}
                            {inputType === "email" && (
                              <input
                                type="email"
                                value={formData[id] || ""}
                                className={styles.fieldStylesQuestions}
                                onChange={(e) =>
                                  handleInputChange(id, e.target.value)
                                }
                              />
                            )}
                            {inputType === "date" && (
                              <input
                                type="date"
                                value={formData[id] || ""}
                                className={styles.fieldStylesQuestions}
                                onChange={(e) =>
                                  handleInputChange(id, e.target.value)
                                }
                              />
                            )}
                            {inputType === "time" && (
                              <input
                                type="time"
                                value={formData[id] || ""}
                                className={styles.fieldStylesQuestions}
                                onChange={(e) =>
                                  handleInputChange(id, e.target.value)
                                }
                              />
                            )}
                            {inputType === "password" && (
                              <input
                                type="password"
                                value={formData[id] || ""}
                                className={styles.fieldStylesQuestions}
                                onChange={(e) =>
                                  handleInputChange(id, e.target.value)
                                }
                              />
                            )}
                          </div>
                        )}
                        {fieldType === "radioButton" && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            {options.map((option) => (
                              <label
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginTop: "7px",
                                }}
                                className={styles.radioLabel}
                                key={option}
                              >
                                <input
                                  type="radio"
                                  name={`radio_${id}`}
                                  value={option}
                                  className={styles.radioLabel}
                                  checked={formData[id] === option}
                                  onChange={() => handleInputChange(id, option)}
                                />
                                {option}
                              </label>
                            ))}
                          </div>
                        )}
                        {fieldType === "dropdown" && (
                          <>
                            <Select
                              value={options.find(
                                (option) => option.value === formData[id]
                              )}
                              options={[
                                { label: "Select...", value: null },
                                ...options.map((option) => ({
                                  label: option,
                                  value: option,
                                })),
                              ]}
                              onChange={(selectedOption) =>
                                handleInputChange(
                                  id,
                                  selectedOption ? selectedOption.value : null
                                )
                              }
                              className={styles.fieldStylesMultiDropDown}
                            />
                          </>
                        )}
                        {fieldType === "medicationField" && (
                          <Medication
                            onMedicationDataChange={handleMedicationDataChange}
                          />
                        )}
                        {fieldType === "multiSelect" && (
                          <Select
                            isMulti
                            options={options.map((option) => ({
                              value: option,
                              label: option,
                            }))}
                            value={(formData[id] || []).map((option) => ({
                              value: option,
                              label: option,
                            }))}
                            className={styles.fieldStylesMultiDropDown}
                            onChange={(selectedOptions) =>
                              handleInputChange(
                                id,
                                selectedOptions.map((option) => option.value)
                              )
                            }
                          />
                        )}
                        {/* ... Other field types ... */}
                        {errors[id] && (
                          <p className={styles.error}>{errors[id]}</p>
                        )}
                        <Divider className={styles.dividerStyles} />
                      </div>
                    );
                  })}
                  <label className={styles.mandatoryMessage}>
                    Fill all mandatory * questions to submit.
                  </label>
                  <div className={styles.submitContainer}>
                    <button
                      type="submit"
                      className={styles.nextButton}
                      disabled={isSubmitDisabled}
                    >
                      SUBMIT
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        <Backdrop open={isFetchingData} style={{ zIndex: 1, color: "#fff" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          key={vertical + horizontal}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarColor}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default JsonForm;
