import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./SuccessPage.module.css";

const SuccessPage = () => {
  const location = useLocation();
  const phoneNumber = location.state?.phoneNumber;

  const handleWhatsAppClick = () => {
    // Check if the user is on a mobile device
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // Open WhatsApp on the mobile device
      window.location.href = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    } else {
      // Redirect to the WhatsApp web page
      window.location.href = `https://web.whatsapp.com/send?phone=${phoneNumber}`;
    }
  };

  return (
    <div className={styles.containerBody}>
      <div className={styles.popUpContainer}>
        <div className={styles.popFirstHalf}>
          <label className={styles.successLabel}>Success</label>
          <div className={styles.successCheckmark}>
            <div className={styles.checkIcon}>
              <span className={`${styles.iconLine} ${styles.lineTip}`}></span>
              <span className={`${styles.iconLine} ${styles.lineLong}`}></span>
              <div className={styles.iconCircle}></div>
              <div className={styles.iconFix}></div>
            </div>
          </div>
        </div>
        <div className={styles.popSecondHalf}>
          <label className={styles.successMessage}>
            You have successfully registered on Diabbot
          </label>
          <label className={styles.successMessage2}>
            You'll receive a message on WhatsApp shortly.
          </label>
          {/* <button
            className={styles.whatsAppClick}
            onClick={handleWhatsAppClick}
          >
            Go to WhatsApp
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
