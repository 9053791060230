export const BASE_URL = "diabbotbackend.oxylym.com:6500";

const constants = {
  BACKEND_URL: "https://diabbotbackend.oxylym.com",
  API_URL: `https://${BASE_URL}`,
  WHATSAPP_TEMPLATE_URL: "https://test.oxylym.com/whatsapp/templates/text",
  AGENT_ID: "355cb16e-3b42-4bf3-b26e-d8937e32aee1",
};

export default constants;
