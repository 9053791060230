// reducer.js
const initialState = {
    sessionStart: false,
  };
  
  const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'START_SESSION':
        return { ...state, sessionStart: true };
      case 'END_SESSION':
        return { ...state, sessionStart: false };
      default:
        return state;
    }
  };
  
  export default sessionReducer;
  